import React from "react";
import * as Icon from "react-feather";

import IconLogo from "assets/img/icon.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ModalContact } from "../Modals";
import LanguageSelector from "../LangSelector";
import config from "config";

import "./index.css";

function CommanHeader(props) {
  const contactUsref = React.useRef();

  const { t } = useTranslation();

  //Used to open the contact us modal without clicking on button
  const openContactUsModal = () => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("contact")) {
      contactUsref.current.click();
    }
  };

  React.useEffect(() => {
    openContactUsModal();
  }, []);

  window.onload = function () {
    var navsBtn = document.querySelectorAll(".dropdown");
    for (var i = 0; i < navsBtn.length; i++) {
      var navbtn = navsBtn[i];
      navbtn.onclick = function () {
        document
          .getElementsByClassName("navbar-collapse")[0]
          .classList.remove("show");
      };
    }
  };
  return (
    <div>
      <ModalContact {...props} />

      <header className="select-none">
        <nav
          className="navbar navbar-main navbar-expand-lg navbar-light"
          id="navbar-main"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                alt="ACV - Tangible IT"
                src={IconLogo}
                className="navbar-logo"
                id="navbar-logo"
                // style={{ width: "150px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-main-collapse"
              aria-controls="navbar-main-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navbar-collapse-overlay"
              id="navbar-main-collapse"
            >
              <div className="position-relative">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-main-collapse"
                  aria-controls="navbar-main-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <Icon.X size="18" />
                </button>
              </div>
              <ul className="navbar-nav ml-lg-auto">
                <li
                  className="nav-item nav-item-spaced dropdown dropdown-animate"
                  data-toggle="hover"
                >
                  <a
                    className="nav-link"
                    href="/parent"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("landing").header[0][0]}
                  </a>
                  <div className="dropdown-menu dropdown-menu-md p-0">
                    <ul className="list-group list-group-flush px-lg-4">
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/attendance"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/calendar.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {" "}
                                {t("Products")[0].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[0].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/dashboard"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/chart.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {" "}
                                {t("Products")[1].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[1].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/whiteboard"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/whiteboard.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("Products")[2].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[2].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/assignment"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/assignment.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("Products")[3].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[3].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/events"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/event.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("Products")[4].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[4].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/feedback"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/feedback.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("Products")[5].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[5].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/bookings"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/booking (1).png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("Products")[7].name}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("Products")[7].tagline}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="nav-item nav-item-spaced dropdown dropdown-animate"
                  data-toggle="hover"
                >
                  <Link
                    className="nav-link"
                    to="/authority-info"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("landing").header[1][0]}
                  </Link>
                  <div className="dropdown-menu dropdown-menu-md p-0">
                    <ul className="list-group list-group-flush px-lg-4">
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/leadership"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/leadership.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[1][1][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[1][1][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/educator"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/educator.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[1][2][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[1][2][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/students"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/student.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[1][3][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[1][3][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="nav-item nav-item-spaced dropdown dropdown-animate"
                  data-toggle="hover"
                >
                  <Link
                    className="nav-link"
                    to="/prices"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("landing").header[3][0]}
                  </Link>
                  <div className="dropdown-menu dropdown-menu-md p-0">
                    <ul className="list-group list-group-flush px-lg-4">
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/prices"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/plan.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[3][1][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[3][1][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/credits"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/credit.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[3][2][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[3][2][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu"
                        data-toggle="hover"
                      >
                        <a
                          href="/storage"
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex">
                            <figure>
                              <img
                                alt="ACV - Tangible IT"
                                src={`https://${config.STORAGE}.blob.core.windows.net/react-images/storage.png`}
                                className="svg-inject img-fluid"
                              />
                            </figure>
                            <div className="ml-3">
                              <h6 className="heading mb-0">
                                {t("landing").header[3][3][0]}
                              </h6>
                              <p className="mb-0 fs-6">
                                {t("landing").header[3][3][1]}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item nav-item-spaced">
                  <a
                    className="nav-link cur-pointer"
                    data-toggle="modal"
                    data-target="#contactusModal"
                    ref={contactUsref}
                  >
                    {t("landing").header[4]}
                  </a>
                </li>
                <li className="nav-item nav-item-spaced d-lg-none d-sm-block ml-n3">
                  <LanguageSelector />
                </li>
              </ul>
              <ul className="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => window.open(config.ACV_URL, "_blank")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("landing").header[5]}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/prices"
                    className="btn btn-sm btn-primary btn-icon ml-3"
                  >
                    <span className="btn-inner--icon">
                      <Icon.ShoppingBag size="16" />
                    </span>{" "}
                    <span className="btn-inner--text">
                      {t("landing").header[6]}
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item nav-item-spaced dropdown dropdown-animate"
                  data-toggle="hover"
                >
                  <LanguageSelector />
                </li>
              </ul>
              <div className="d-lg-none px-4 text-center mb-2">
                <a
                  className="btn btn-block btn-sm btn-secondary"
                  onClick={() => window.open(config.ACV_URL, "_blank")}
                >
                  {t("landing").header[5]}
                </a>
              </div>
              <div className="d-lg-none px-4 text-center">
                <Link to="/prices" className="btn btn-block btn-sm btn-primary">
                  {t("landing").header[6]}
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default CommanHeader;
