import axios from "axios";
import config from "config";
import { useEffect, useState } from "react";

export const useVerifyCouponHandlers = ({ defaultCoupon, onAfterVerified }) => {
  const [loader, setLoader] = useState(false);
  const url = new URL(window.location.href);
  const [msgData, setMsgData] = useState(null);
  const [coupon, setCoupon] = useState(url.searchParams.get("coupon"));
  useEffect(() => {
    if (defaultCoupon) {
      setCoupon(defaultCoupon);
    }
  }, [defaultCoupon]);

  const verifyCoupon = () => {
    setLoader(true);
    if (!coupon) {
      setLoader(false);
      localStorage.removeItem("toastMessage");
      return;
    }
    setMsgData?.({
      message: "Verifying your coupon...",
      type: "Warning",
    });

    localStorage.setItem(
      "toastMessage",
      JSON.stringify({
        message: "Verifying your coupon...",
        type: "Warning",
      })
    );
    axios
      .post(`${config.ENDPOINT}/api/admin/verify-coupon-code`, {
        Coupon: coupon,
        language: localStorage.getItem("i18nextLng"),
      })
      .then((res) => {
        const msgData = { message: res.data.message, type: res.data.status };
        setMsgData?.(msgData);
        localStorage.setItem("toastMessage", JSON.stringify(msgData));
        setLoader(false);
        onAfterVerified?.();
        setTimeout(() => {
          localStorage.removeItem("toastMessage");
          setMsgData(null);
        }, 5000);
        // document.getElementById("close-modal").click();
      })
      .catch((err) => {
        console.log(err.response?.data?.message);
        const errorMsg = {
          message: err.response?.data?.message,
          type: "Error",
        };
        setMsgData?.(errorMsg);
        localStorage.setItem("toastMessage", JSON.stringify(errorMsg));
        setLoader(false);
        onAfterVerified?.();

        setTimeout(() => {
          localStorage.removeItem("toastMessage");
          setMsgData(null);
        }, 5000);
      });
  };

  return {
    verifyCoupon,
    coupon,
    isLoading: loader,
  };
};
